<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="充电记录"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-dropdown-menu class="van-hairline--bottom" active-color="#1989fa">
      <van-dropdown-item
        v-model="state.order_status"
        :options="allStatus"
        @change="onStatusChange"
      />
      <van-dropdown-item title="日期" @open="state.showCalendar = true" />
    </van-dropdown-menu>
    <van-calendar
      v-model:show="state.showCalendar"
      type="range"
      position="right"
      color="#1989fa"
      :allow-same-day="true"
      :min-date="new Date(new Date() - 2 * 15552000000)"
      :max-date="new Date()"
      :round="false"
      @confirm="onDateRangeConfirm"
    />
    <van-list
      v-model:loading="state.loading"
      v-model:error="state.error"
      :finished="state.finished"
      finished-text="没有更多了"
      error-text="拉取失败，点击重新加载"
      @load="loadPage"
    >
      <van-cell
        v-for="(item, index) in state.items"
        :key="index"
        :title="formatDateTime(item.created_at)"
        :to="{
          name: 'UserChargingOrderDetail',
          query: { order_id: item.order_id },
        }"
        is-link
      >
        <template #value>
          <span v-if="item.order_status == 3" class="text-danger"
            >{{ new Number(item.total_amount * 0.01).toFixed(2) }}元</span
          >
          <span v-else-if="item.order_status == 1" class="text-success"
            >充电中</span
          >
          <span v-else-if="item.order_status == 2" class="text-warning"
            >待结算</span
          >
          <span v-else-if="item.order_status == 4" class="text-info"
            >已关闭</span
          >
          <span v-else-if="item.order_status == 0" class="text-primary"
            >已创建</span
          >
          <span v-else class="text-secondary">未知</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { reactive } from "vue";
import { List, Toast, Calendar, DropdownMenu, DropdownItem } from "vant";
import { getChargingOrderLog } from "@/api/account.service";
import Moment from "moment";
export default {
  components: {
    [List.name]: List,
    [Calendar.name]: Calendar,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },

  setup() {
    const state = reactive({
      page: 1,
      items: [],
      loading: false,
      finished: false,
      showCalendar: false,

      // 搜索表单字段
      order_status: -1,
      start_date: "",
      end_date: "",
    });

    const allStatus = [
      { text: "全部", value: -1 },
      { text: "已创建", value: 0 },
      { text: "充电中", value: 1 },
      { text: "未结算", value: 2 }, // 已结束
      { text: "已结算", value: 3 },
      // { text: "已关闭", value: 4 },
    ];

    const loadPage = async () => {
      state.loading = true;
      if (state.page == 1) {
        state.items = [];
      }
      try {
        const { result } = await getChargingOrderLog({
          page: state.page,
          order_status: state.order_status,
          start_date: state.start_date,
          end_date: state.end_date,
        });
        if (result.items.length > 0) {
          state.items.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length == 0) {
          state.finished = true;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const onStatusChange = () => {
      state.page = 1;
      loadPage();
    };

    const formatDate = (date) =>
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    const onDateRangeConfirm = (values) => {
      const [start, end] = values;
      state.start_date = formatDate(start);
      state.end_date = formatDate(end);
      state.showCalendar = false; // 提示: 这样会导致日历立即隐藏, 没有动画
      state.page = 1;
      loadPage();
    };

    const formatDateTime = (time) => {
      return Moment(time * 1000).format("YYYY-MM-DD HH:mm:ss");
    };

    return {
      state,
      allStatus,
      loadPage,
      onStatusChange,
      formatDateTime,
      onDateRangeConfirm,
    };
  },
};
</script>
